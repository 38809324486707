import { Button, ButtonProps, Image } from "@chakra-ui/react";
import exportIcon from "../../images/SVG/export.svg";

interface IAction extends ButtonProps {
  icon?: any;
  text: string;
}

const ActionButton = ({ icon, text, ...props }: IAction) => {
  return (
    <Button
      bg="brand.action"
      color="white"
      _hover={{ bg: "#4c6e80c4" }}
      borderRadius="30px"
      minW="100px"
      {...props}
    >
      {icon && (
        <Image w="20px" h="20px" src={icon} alt="export-icon" mr="10px" />
      )}
      {text}
    </Button>
  );
};

export default ActionButton;
