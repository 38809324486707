import {
  Center,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
  TextProps,
} from "@chakra-ui/react";
import React from "react";
import appLogo from "../../images/SVG/main-logo.svg";
import {Link} from "react-router-dom";
import {parserForm} from "../../helpers/form";
import {inputLabeText, loginInputStyle} from "../../theme/theme";
import ActionButton from "../../components/ActionButton/ActionButton";
import {useAppDispatch} from "../../redux/hooks/hooks";
import {login} from "../../redux/slices/userSlice";
import {useLoginMutation} from "../../redux/services/auth";

const Login = () => {
  const dispatch = useAppDispatch();
  const [loginReq, {isLoading}] = useLoginMutation();

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    const {email, password} = parserForm(event);
    loginReq({email, password}).unwrap().then(({data}) => {
      if(data){
        dispatch(login({email: data.user.email, token: data.token, name: data.user.full_name}));
      }
    });
  };

  return (
    <Center w="100vw" h="100vh" overflow="hidden" flexDir="column">
      <Image
        w="200px"
        h="70px"
        mb="20px"
        src={appLogo}
        alt="application-logo"
      />
      <form onSubmit={handleLogin}>
        <Flex {...boxStyles}>
          <Text {...titleStyle} color="brand.action">
            Login
          </Text>
          <FormControl isRequired mb="20px">
            <FormLabel {...inputLabeText}>Email</FormLabel>
            <Input name="email" type="email" {...loginInputStyle} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel {...inputLabeText}>Password</FormLabel>
            <Input name="password" type="password" {...loginInputStyle} />
          </FormControl>
          <ActionButton
            text="Login"
            m="30px"
            type="submit"
            isLoading={isLoading}
          />
          <Text
            fontSize="20px"
            mt="auto"
            as={Link}
            to={`/signup`}
            color="brand.action"
          >
            Sign up
          </Text>
        </Flex>
      </form>
    </Center>
  );
};

const boxStyles: FlexProps = {
  p: "40px",
  pb: "10px",
  flexDir: "column",
  align: "center",
  // bg: "white",
  borderRadius: "15px",
  boxShadow: "xlx",
  minW: "500px",
  bg: "brand.bars",
  // minH: "400px",
};

const titleStyle: TextProps = {
  mb: "15px",
  fontSize: "22px",
  fontWeight: "bold",
};

export default Login;
