import { HamburgerIcon, IconProps } from "@chakra-ui/icons";
import { Center, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";
import { IRoute, routes } from "../../routes/routes";

const MenuComponent = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = (route: IRoute) =>
    navigate(`${route.path}${route?.indexPath || ""}`);

  return (
    <Flex w="70px" h="full" flexDir="column" overflow="hidden" align="flex-end">
      <Center h="70px" w="full" bg="brand.bars">
        <Button
          p="5px"
          bg="brand.bars"
          onClick={toggleMenu}
          zIndex={isOpen ? "9999999" : "9"}
        >
          <HamburgerIcon {...menuIconStyle} />
        </Button>
      </Center>
      <Box mt="20px">
        {routes.map((route) =>
          route.renderIcon ? (
            <Center
              w="59px"
              h="59px"
              onClick={() => handleNavigate(route)}
              _hover={{ cursor: "pointer" }}
              pos="relative"
            >
              <Box
                pos="absolute"
                top="0px"
                right="-80px"
                w="59px"
                h="59px"
                borderTopLeftRadius="50%"
                borderBottomLeftRadius="50%"
                transform={
                  location.pathname.includes(route.path)
                    ? "translateX(-80px)"
                    : ""
                }
                boxShadow={
                  location.pathname.includes(route.path)
                    ? "0px 0px 10px 0px #4C6E80"
                    : "none"
                }
                bg={
                  location.pathname.includes(route.path)
                    ? "brand.action"
                    : "transparent"
                }
                transition="all 150ms ease-in-out"
              ></Box>
              <Box
                _hover={{
                  transform: !location.pathname.includes(route.path)
                    ? "scale(1.05)"
                    : "",
                }}
                transform={
                  location.pathname.includes(route.path) ? "scale(1.2)" : ""
                }
              >
                {route.renderIcon(location.pathname)}
              </Box>
            </Center>
          ) : null
        )}
      </Box>
      <Sidebar isOpen={isOpen} onClose={toggleMenu} />
    </Flex>
  );
};

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

const SidebarContent = ({ onClose }: { onClose: () => void }) => {
  const dayName = moment(Date.now()).format("dddd");
  const date = moment(Date.now()).format("DD/MM/YYYY");
  const navigate = useNavigate();
  const location = useLocation();

  const handleRedirect = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <Flex w="100%" flexDir="column" align="center">
      <Flex
        h="70px"
        w="100%"
        flexDir="column"
        align="flex-start"
        pl="15px"
        justify="center"
      >
        <Text textAlign="left" color="brand.action">
          {dayName}
        </Text>
        <Text textAlign="left" color="brand.action">
          {date}
        </Text>
      </Flex>
      <VStack w="90%" mt="20px" spacing="10px">
        {routes.map((icon) => (
          <>
            {icon.renderIcon ? (
              <Button
                h="50px"
                pl="10px"
                borderRadius="20px"
                bg={
                  location.pathname.includes(icon.path)
                    ? "brand.action"
                    : "white"
                }
                _hover={{
                  cursor: "pointer",
                  boxShadow: "0px 0px 11px -4px #4C6E80",
                }}
                onClick={() => handleRedirect(icon.path)}
                w="100%"
              >
                {icon.renderIcon && icon.renderIcon(location.pathname)}
                <Text
                  textAlign="left"
                  w="140px"
                  ml="10px"
                  color={
                    location.pathname.includes(icon.path)
                      ? "brand.white"
                      : "brand.action"
                  }
                >
                  {icon.pageTitle}
                </Text>
              </Button>
            ) : null}
          </>
        ))}
      </VStack>
    </Flex>
  );
};

const Sidebar = ({ isOpen, onClose }: Props) => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent w="250px" maxW="250px">
          <DrawerBody p="0px" bg="brand.bars">
            <SidebarContent onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

const menuIconStyle: IconProps = {
  w: "25px",
  h: "25px",
  color: "brand.action",
  opacity: "0.7",
  transition: "all 250ms easy-in",
  _hover: {
    cursor: "pointer",
    opacity: "1",
  },
};

export default MenuComponent;
