import { Route, Routes } from "react-router";
import { routes } from "./routes";
import React from "react";
import Fallback from "../components/Fallback/Fallback";

const AllRoutes = () => {
  return (
    <React.Suspense fallback={<Fallback />}>
      <Routes>
        {routes.map((route, i) => (
          <Route
            key={i}
            path={route.path + route.params}
            element={route.element}
          />
        ))}
      </Routes>
    </React.Suspense>
  );
};

export default AllRoutes;
