import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {RootState} from "../../store";
import {SERVER_URL} from "../../../constants";

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_URL}/admin`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (build) => ({
    getProducts: build.query<any, number>({
      query: (page) => ({
        url: `/products?page=${page}`,
      }),
      transformResponse: (response: { data: any }) => response.data,
    }),
    createProduct: build.mutation<any, any >({
      query: (data) => ({
        url: '/products',
        body: data,
        method: 'POST'
      })
    })
  }),
});

export const {useGetProductsQuery, useCreateProductMutation} = productsApi;