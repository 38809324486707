import {
  CheckboxProps,
  extendTheme,
  FlexProps,
  FormLabelProps,
  InputProps,
  TextProps,
} from "@chakra-ui/react";
import { ChakraStylesConfig } from "chakra-react-select";

export const pageWrapStyles: FlexProps = {
  flexDir: "column",
  pt: "0px",
  pb: "15px",
  maxW: "1250px",
  pl: { lg: "25px", md: "8px" },
  pr: { lg: "25px", md: "8px" },
  h: "auto",
  w: "100%",
};

export const mainContentWrapStyle: FlexProps = {
  flexDir: "column",
  // pt: "20px",
  align: "center",
  w: "full",
  maxW: "calc(100% - 20px)",
  pl: "10px",
  pr: "10px",
  maxH: "calc(100vh - 90px)",
  m: "0 auto",
  mt: "20px",
  overflow: "auto",
  h: "full",
  sx: {
    "&::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
      borderRadius: "15px",
      backgroundColor: `rgba(0, 0, 0, 0.03)`,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: `#c6d9e47a`,
      borderRadius: "15px",
    },
  },
};

export const pageTitleText: TextProps = {
  fontSize: "20px",
  lineHeight: "20px",
  fontWeight: "bold",
  color: "brand.action",
};

export const largeText: TextProps = {
  fontSize: "18px",
  lineHeight: "18px",
  fontWeight: "normal",
  color: "brand.action",
};

export const ordinaryText: TextProps = {
  fontSize: "14px",
  lineHeight: "14px",
  fontWeight: "normal",
};

export const inputLabeText: FormLabelProps = {
  fontSize: "14px",
  lineHeight: "14px",
  fontWeight: "bold",
  color: "grey",
};

export const inputStyle: InputProps = {
  p: "5px",
  pl: "10px",
  fontSize: "16px",
  h: "36px",
  borderRadius: "8px",
  border: "1px solid #c6d9e47a",
  boxShadow: "0 0 4px #c6d9e47a",
  bg: "white",
  _focus: {
    boxShadow: "0 0 6px #c6d9e47a",
  },
};

export const checkboxStyles: CheckboxProps = {
  colorScheme: "brand.action",
  _checked: {
    bg: "brand.action",
    bgColor: "brand.action",
    borderColor: "brand.action",
    color: "brand.action",
  },
};

export const loginInputStyle: InputProps = {
  bg: "brand.secondary",
  h: "40px",
  fontSize: "18px",
};

export const linkStyles: TextProps = {
  _hover: {
    cursor: "pointer",
    textDecoration: "underline",
  },
};

export const theme = extendTheme({
  shadows: {
    xlx: "0 0 7px #c6d9e47a",
  },
  styles: {
    global: {
      "div, span, button, p, input": {
        fontFamily: "Avenir, sans-serif",
        letterSpacing: "-0.17px",
        lineHeight: "18px",
      },
      input: {},
      textarea: {
        _focus: {
          border: "1px solid #4990D2",
        },
      },
    },
  },
  variant: {
    first: {
      colore: "red",
    },
  },
  colors: {
    line: {
      blue: "#4990D2",
      sky: "#A2CBF0",
      yellow: "#FFE6AD",
      pink: "#D27266",
      lightBordo: "#891308",
      bordo: "#6E1105",
    },
    brand: {
      button: "#ADD8E6",
      red: "#D2042D",
      500: "#891308",
      bordo: "#891308",
      action: "#4C6E80",
      bars: "#c6d9e47a",
      secondary: "#C6D9E4",
      green: "#27AE60",
      bordo2: "#6E1105",
      yellow: "#FFE6AD",
      black: "#161621",
      white: "#FFFFFF",
      darkBlue: "#285C8C",
      blue: "#4281BD",
      grey: "rgba(57, 57, 69, 0.6)",
      border: "#797983",
      border1: "#BDBDBD",
      border2: "#E5E5E5",
      border3: "#C9CAD1",
      border4: "#DDE2E5",
      bgWhite: "rgba(255, 255, 255, 0.9)",
      bgGrey: "#F2F2F2",
      sky: "#4990D2",
      sky2: "#0F73AB",
      text: "#6E798C",
      text2: "#495057",
      text3: "#333533",
      lightGrey: "#E8E5E5",
      lightBlue: "rgba(73, 144, 210, 0.1)",
      greyBtn: "#F2F2F2",
      colorBtn: "#BDBDBD",
    },
  },
});

export const chakraStylesSelect: ChakraStylesConfig = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    background: "transparent",
    p: 0,
    w: "40px",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  container: (provided, state) => ({
    ...provided,
    color: "black",
    minW: "150px",
    borderRadius: "0px",
    h: "36px",
    maxH: "36px",
    _hover: {
      border: "transparent",
    },
  }),
  menuList: (provided, state) => ({
    ...provided,
    borderRadius: "0px",
    w: "auto",
    minW: "150px",
  }),
  menu: (provided, state) => ({
    ...provided,
    mt: "5px",
    borderRadius: "0px",
    w: "auto",
  }),
  option: (provided, state) => ({
    ...provided,
    p: "7px 20px",
    w: "auto",
    _selected: {
      bg: "black",
      color: "white",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    h: "36px",
    mt: "0px",
    maxH: "36px",
    borderRadius: "0px",
    bg: "brand.white",
    border: "1px solid lightgrey",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "brand.black",
    opacity: "0.5",
  }),
  input: (provided, state) => ({
    ...provided,
    display: "none",
  }),
};
