import {
  Menu,
  IconButton,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Text,
  MenuListProps,
} from "@chakra-ui/react";
import { BellIcon, EmailIcon } from "@chakra-ui/icons";

const notifications = [
  { text: "Some Notification Text", readed: false },
  { text: "Some Notification Text", readed: false },
  { text: "Some Notification Text", readed: false },
  { text: "Some Notification Text", readed: false },
  { text: "Some Notification Text", readed: true },
  { text: "Some Notification Text", readed: true },
  { text: "Some Notification Text", readed: true },
  { text: "Some Notification Text", readed: true },
  { text: "Some Notification Text", readed: true },
  { text: "Some Notification Text", readed: true },
];

const NotificationMenu = () => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<BellIcon w="30px" h="30px" color="brand.action" />}
        variant="outline"
        p="5px"
      />
      <MenuList {...menuListStyles} zIndex="9999999">
        {notifications.map((not) => (
          <MenuItem>
            <Flex
              borderRadius="8px"
              bg="white"
              w="300px"
              justify="space-between"
              align="center"
              p="8px"
            >
              <EmailIcon
                w="20px"
                h="20px"
                color={not.readed ? "lightgrey" : "brand.action"}
              />
              <Text w="100%" textAlign="right">
                {not.text}
              </Text>
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const menuListStyles: MenuListProps = {
  w: "330px",
  bg: "#f0f0f0",
  overflowY: "auto",
  maxHeight: "300px",
  sx: {
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      borderRadius: "8px",
      backgroundColor: `rgba(0, 0, 0, 0.03)`,
    },
    "&::-webkit-scrollbar-thumb": {
      width: "4px",
      backgroundColor: `grey`,
      borderRadius: "8px",
    },
  },
};

export default NotificationMenu;
