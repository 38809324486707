import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {RootState} from "../../store";
import {SERVER_URL} from "../../../constants";


type ClientRequest = {
  page: number,
  search?: string
}

export const clientsApi = createApi({
  reducerPath: "clientsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_URL}/admin`,
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).user.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (build) => ({
    getClients: build.query<any, ClientRequest>({
      query: ({search = '', page}) => ('/clients?' + `page=${page}`),
      transformResponse: (response: { data: any },) => response.data,
    }),
  }),
});

export const {useGetClientsQuery} = clientsApi;