import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from "@reduxjs/toolkit";
import { createStandaloneToast } from "@chakra-ui/react";
// import { getErrorRetail } from "../helpers/common";
import { logout } from "../slices/userSlice";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (
        action?.payload?.status === 401 ||
        action?.payload?.originalStatus === 401
      ) {
        api.dispatch(logout());
      }
      // toast({
      //   title: `${"טעות"}`,
      //   description: getErrorRetail(action.payload),
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      // });
    }

    return next(action);
  };
