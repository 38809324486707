import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUserState {
  email: string | any;
  name?: string;
  token?: string;
  image?: string;
}

const initialState: IUserState = {
  email: "admin@gmail.com",
  name: "asdasd",
  token: "dasfca",
  image: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<IUserState>) => {

      state.email = action.payload.email;
      state.token = action.payload.token;
    },
    singup: (state, action: PayloadAction<IUserState>) => {
      state.email = action.payload.email;
      state.token = action.payload.token;
    },
    logout: (state) => {
      state.email = "";
      state.token = "";
    },
  },
  extraReducers: (builder) => {
    //LOGIN RESPONSE DATA
    // builder.addMatcher(
    //   userApi.endpoints.login.matchFulfilled,
    //   (state, { payload }) => {
    //     state = payload;
    //   }
    // );
  },
});

export const { logout, login, singup } = userSlice.actions;
export default userSlice.reducer;
