interface IPaths {
  dashboard: string;
  buisiness: string;
  clients: string;
  products: string;
  appeals: string;
  information: string;
  sendNotification: string;
  maintenance: string;
  clientAccount: string;
}

export const paths: IPaths = {
  dashboard: "/home/dashboard",
  buisiness: "/home/buisiness",
  clients: "/home/clients",
  products: "/home/products",
  appeals: "/home/appeals",
  information: "/home/information",
  sendNotification: "/home/send-notification",
  maintenance: "/home/maintenance",
  clientAccount: "/home/clients/account",
};
