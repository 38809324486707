import { Button, ButtonProps, Image } from "@chakra-ui/react";

interface IDanger extends ButtonProps {
  icon?: any;
  text: string;
}

const DangerButton = ({ icon, text, ...props }: IDanger) => {
  return (
    <Button
      bg="brand.red"
      color="white"
      _hover={{ bg: "brand.red" }}
      borderRadius="30px"
      minW="100px"
      {...props}
    >
      {icon && (
        <Image w="20px" h="20px" src={icon} alt="export-icon" mr="10px" />
      )}
      {text}
    </Button>
  );
};

export default DangerButton;
