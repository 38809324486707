import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {RootState} from "../../../store";
import {SERVER_URL} from "../../../../constants";

export const productsCategoriesApi = createApi({
  reducerPath: "productsCategoriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_URL}/admin/products/categories`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (build) => ({
    getCategories: build.query<any, void>({
      query: () => ({
        url: "",
      }),
      transformResponse: (response: { data: any }) => response.data,
    }),
  }),
});

export const {useGetCategoriesQuery} = productsCategoriesApi;