import { Flex } from "@chakra-ui/react";
import Header from "../../components/Header/Header";
import MenuComponent from "../../components/Menu/Menu";
import AllRoutes from "../../routes/AllRoutes";
import { mainContentWrapStyle } from "../../theme/theme";

const Main = () => {
  return (
    <Flex w="100vw" h="100vh">
      <Flex w="calc(100vw - 60px)" flexDir="column" h="full">
        <Header />
        <Flex {...mainContentWrapStyle}>
          <AllRoutes />
        </Flex>
      </Flex>
      <MenuComponent />
    </Flex>
  );
};

export default Main;
