import { useEffect } from "react";
import { useAppSelector } from "./redux/hooks/hooks";
import { useLocation, useNavigate } from "react-router";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import Main from "./pages/Main/Main";
import Signup from "./pages/Signup/Signup";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuth = useAppSelector((state) => state.user.token);

  useEffect(() => {
    if (isAuth) {
      if (location.pathname.includes("home")) {
        navigate(location.pathname);
        return;
      }
      navigate("/home/dashboard");
    } else if (location.pathname.includes("signup")) {
      return;
    } else {
      navigate("/login");
    }
  }, [isAuth]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="*" element={<Main />} />
    </Routes>
  );
};

export default App;
