import {
  Button,
  Center,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
  TextProps,
} from "@chakra-ui/react";
import React from "react";
import appLogo from "../../images/SVG/main-logo.svg";
import { Link } from "react-router-dom";
import { parserForm } from "../../helpers/form";
import { inputLabeText, loginInputStyle } from "../../theme/theme";
import ActionButton from "../../components/ActionButton/ActionButton";
import { useAppDispatch } from "../../redux/hooks/hooks";
import { login } from "../../redux/slices/userSlice";
import { authApi } from "../../redux/services/auth";

const Signup = () => {
  const [signup, { isLoading }] = authApi.useSignupMutation();

  const handleSignup = (event: React.FormEvent) => {
    event.preventDefault();
    const { email, password, name } = parserForm(event);
    signup({ email, password, name });
  };

  return (
    <Center w="100vw" h="100vh" overflow="hidden" flexDir="column">
      <Image
        w="200px"
        h="70px"
        mb="20px"
        src={appLogo}
        alt="application-logo"
      />
      <form onSubmit={handleSignup} autoComplete="off">
        <Flex {...boxStyles}>
          <Text {...titleStyle} color="brand.action">
            Sign Up
          </Text>
          <FormControl isRequired mb="20px">
            <FormLabel {...inputLabeText}>User Name</FormLabel>
            <Input
              name="name"
              type="text"
              autoComplete="user-signup"
              {...loginInputStyle}
            />
          </FormControl>
          <FormControl isRequired mb="20px">
            <FormLabel {...inputLabeText}>Email</FormLabel>
            <Input
              name="email"
              type="email"
              autoComplete="email1"
              {...loginInputStyle}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel {...inputLabeText}>Password</FormLabel>
            <Input
              name="password"
              bg="#C6D9E4"
              autoComplete="password1"
              {...loginInputStyle}
            />
          </FormControl>
          <ActionButton
            text="Sign Up"
            m="30px"
            type="submit"
            isLoading={isLoading}
          />
          <Text
            as={Link}
            to={`/login`}
            color="brand.action"
            fontSize="20px"
            mt="auto"
          >
            Login
          </Text>
        </Flex>
      </form>
    </Center>
  );
};

const boxStyles: FlexProps = {
  p: "40px",
  pb: "10px",
  flexDir: "column",
  align: "center",
  // bg: "white",
  borderRadius: "15px",
  boxShadow: "xlx",
  minW: "500px",
  bg: "brand.bars",
};

const titleStyle: TextProps = {
  mb: "15px",
  fontSize: "22px",
  fontWeight: "bold",
};

export default Signup;
