import {AuthResponse, LoginUserRequest, Response, SignupUser} from "../../../interfaces";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {SERVER_URL} from "../../../constants";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
  }),
  endpoints: (build) => ({
    login: build.mutation<Response<AuthResponse>, LoginUserRequest>({
      query: (data) => ({
        url: "/admin/login",
        method: "POST",
        body: data,
      }),
    }),
    signup: build.mutation<Response<AuthResponse>, SignupUser>({
      transformResponse: (response:any ) => response.data,
      query: (data) => ({
        url: "/reg",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {useLoginMutation, useSignupMutation} = authApi;