import { Icon } from "@chakra-ui/react";

export const BussinessIcon = ({ isActive }: { isActive: boolean }) => {
  const color = isActive ? "white" : "#4C6E80";
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="25px"
      height="25px"
      viewBox="0 0 31.666 31.666"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M11.452,31.666v-5.879h8.763v5.879h6.604V0H4.847v31.666H11.452z M20.215,2.909h3.696v3.696h-3.696V2.909z M20.215,8.282
		h3.696v3.697h-3.696V8.282z M20.215,13.656h3.696v3.695h-3.696V13.656z M20.215,19.028h3.696v3.698h-3.696V19.028z M13.986,2.909
		h3.697v3.696h-3.697V2.909z M13.986,8.282h3.697v3.697h-3.697V8.282z M13.986,13.656h3.697v3.695h-3.697V13.656z M13.986,19.028
		h3.697v3.698h-3.697V19.028z M7.757,2.909h3.696v3.696H7.757V2.909z M7.757,8.282h3.696v3.697H7.757V8.282z M7.757,13.656h3.696
		v3.695H7.757V13.656z M7.757,19.028h3.696v3.698H7.757V19.028z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const HomeIcon = ({ isActive }: { isActive: boolean }) => {
  const color = isActive ? "white" : "#4C6E80";
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      width="25px"
      height="25px"
      y="0px"
      viewBox="0 0 26.39 26.39"
      xmlSpace="preserve"
    >
      <g>
        <g id="c14_house">
          <path
            d="M3.588,24.297c0,0-0.024,0.59,0.553,0.59c0.718,0,6.652-0.008,6.652-0.008l0.01-5.451c0,0-0.094-0.898,0.777-0.898h2.761
			c1.031,0,0.968,0.898,0.968,0.898l-0.012,5.434c0,0,5.628,0,6.512,0c0.732,0,0.699-0.734,0.699-0.734V14.076L13.33,5.913
			l-9.742,8.164C3.588,14.077,3.588,24.297,3.588,24.297z"
            fill={color}
          />
          <path
            fill={color}
            d="M0,13.317c0,0,0.826,1.524,2.631,0l10.781-9.121l10.107,9.064c2.088,1.506,2.871,0,2.871,0L13.412,1.504L0,13.317z"
          />
          <polygon
            fill={color}
            points="23.273,4.175 20.674,4.175 20.685,7.328 23.273,9.525 		"
          />
        </g>
        <g id="Capa_1_216_"></g>
      </g>
    </svg>
  );
};

export const ClientsIcon = ({ isActive }: { isActive: boolean }) => {
  const color = isActive ? "white" : "#4C6E80";
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="25px"
      height="25px"
      viewBox="0 0 35.695 35.695"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill={color}
          d="M11.558,10.767c0-3.473,2.815-6.29,6.289-6.29c3.476,0,6.289,2.817,6.289,6.29c0,3.475-2.813,6.29-6.289,6.29
		C14.373,17.057,11.558,14.243,11.558,10.767z M35.667,22.607l-0.879-5.27c-0.158-0.954-0.961-1.754-1.896-1.984
		c-0.836,0.74-1.932,1.191-3.136,1.191c-1.203,0-2.3-0.452-3.135-1.191c-0.938,0.229-1.739,1.03-1.897,1.984l-0.021,0.124
		c-0.522-0.503-1.17-0.881-1.868-1.052c-1.33,1.176-3.072,1.896-4.987,1.896s-3.657-0.72-4.987-1.896
		c-0.698,0.171-1.346,0.549-1.868,1.052l-0.021-0.124c-0.158-0.954-0.962-1.754-1.897-1.984c-0.835,0.74-1.932,1.191-3.135,1.191
		c-1.204,0-2.3-0.452-3.136-1.191c-0.936,0.229-1.738,1.03-1.896,1.984l-0.879,5.27c-0.189,1.131,0.596,2.057,1.741,2.057h7.222
		l-0.548,3.283c-0.3,1.799,0.948,3.271,2.771,3.271H24.48c1.823,0,3.071-1.475,2.771-3.271l-0.548-3.283h7.222
		C35.071,24.662,35.855,23.738,35.667,22.607z M29.755,15.762c2.184,0,3.954-1.77,3.954-3.954c0-2.183-1.771-3.954-3.954-3.954
		s-3.953,1.771-3.953,3.954C25.802,13.992,27.574,15.762,29.755,15.762z M5.938,15.762c2.183,0,3.953-1.77,3.953-3.954
		c0-2.183-1.771-3.954-3.953-3.954c-2.184,0-3.954,1.771-3.954,3.954C1.984,13.992,3.755,15.762,5.938,15.762z"
        />
      </g>
    </svg>
  );
};

export const ProductsIcon = ({ isActive }: { isActive: boolean }) => {
  const color = isActive ? "white" : "#4C6E80";
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="25px"
      height="25px"
      viewBox="0 0 382.402 382.402"
      xmlSpace="preserve"
    >
      <path
        fill={color}
        d="M381.989,142.631l-15.719-98.925c-2.254-14.193-13.383-25.322-27.576-27.575l-98.924-15.72
	c-10.502-1.669-21.168,1.797-28.682,9.311L9.724,211.086c-12.965,12.966-12.965,33.982,0,46.948l114.645,114.644
	c12.965,12.965,33.98,12.965,46.947-0.002l201.363-201.363C380.193,163.798,383.659,153.132,381.989,142.631z M162.697,346.985
	c-5.066,5.066-13.283,5.066-18.35,0l-108.93-108.93c-5.066-5.067-5.066-13.284,0-18.351c5.064-5.064,13.283-5.066,18.35,0
	l108.93,108.931C167.763,333.701,167.761,341.919,162.697,346.985z M309.237,108.52c-9.764,9.764-25.594,9.763-35.355,0
	c-9.764-9.764-9.766-25.591,0-35.355c9.762-9.764,25.59-9.764,35.355,0C318.999,82.928,318.999,98.757,309.237,108.52z"
      />
    </svg>
  );
};

export const InformationIcon = ({ isActive }: { isActive: boolean }) => {
  const color = isActive ? "white" : "#4C6E80";
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="25px"
      height="25px"
      viewBox="0 0 460 460"
      xmlSpace="preserve"
    >
      <g id="XMLID_1055_">
        <g>
          <path
            fill={color}
            d="M230,0C102.975,0,0,102.975,0,230s102.975,230,230,230s230-102.974,230-230S357.025,0,230,0z M268.333,377.36
			c0,8.676-7.034,15.71-15.71,15.71h-43.101c-8.676,0-15.71-7.034-15.71-15.71V202.477c0-8.676,7.033-15.71,15.71-15.71h43.101
			c8.676,0,15.71,7.033,15.71,15.71V377.36z M230,157c-21.539,0-39-17.461-39-39s17.461-39,39-39s39,17.461,39,39
			S251.539,157,230,157z"
          />
        </g>
      </g>
    </svg>
  );
};

export const AppealsIcon = ({ isActive }: { isActive: boolean }) => {
  const color = isActive ? "white" : "#4C6E80";
  return (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0" fill="none" width="24" height="24" />
      <g>
        <path
          fill={color}
          d="M20 4H4c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h16c1.105 0 2-.895 2-2V6c0-1.105-.895-2-2-2zm0 4.236l-8 4.882-8-4.882V6h16v2.236z"
        />
      </g>
    </svg>
  );
};

export const SendNotificationIcon = ({ isActive }: { isActive: boolean }) => {
  const color = isActive ? "white" : "#4C6E80";
  return (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>ionicons-v5-j</title>
      <path
        fill={color}
        d="M440.08,341.31c-1.66-2-3.29-4-4.89-5.93-22-26.61-35.31-42.67-35.31-118,0-39-9.33-71-27.72-95-13.56-17.73-31.89-31.18-56.05-41.12a3,3,0,0,1-.82-.67C306.6,51.49,282.82,32,256,32s-50.59,19.49-59.28,48.56a3.13,3.13,0,0,1-.81.65c-56.38,23.21-83.78,67.74-83.78,136.14,0,75.36-13.29,91.42-35.31,118-1.6,1.93-3.23,3.89-4.89,5.93a35.16,35.16,0,0,0-4.65,37.62c6.17,13,19.32,21.07,34.33,21.07H410.5c14.94,0,28-8.06,34.19-21A35.17,35.17,0,0,0,440.08,341.31Z"
      />
      <path
        fill={color}
        d="M256,480a80.06,80.06,0,0,0,70.44-42.13,4,4,0,0,0-3.54-5.87H189.12a4,4,0,0,0-3.55,5.87A80.06,80.06,0,0,0,256,480Z"
      />
    </svg>
  );
};

export const MaintenanceIcon = ({ isActive }: { isActive: boolean }) => {
  const color = isActive ? "white" : "#4C6E80";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="30px"
      height="30px"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
      style={{ transition: "all 250ms easy-in" }}
    >
      <path
        fill={color}
        d="M46.5,30.5c0.2,0.5,0.6,0.8,1.1,0.8h27.5c1.3,0,2.7,0.3,3.9,0.8c0.5,0.3,1.1-0.1,1.1-0.8
	c0.1-2.8-2.1-5-4.9-5.1c0,0-0.1,0-0.1,0H45c-0.3,0-0.6,0.2-0.6,0.5c0,0.1,0,0.3,0.1,0.4L46.5,30.5z"
      />
      <path
        fill={color}
        d="M75.1,36.4H46.7c-1.8,0-3.4-1-4.4-2.5l-4.4-7.6c-0.8-1.6-2.5-2.6-4.3-2.6h-8.5c-2.8,0-5,2.2-5,5
	c0,0,0,0.1,0,0.1v45.8c-0.1,2.8,2.1,5,4.9,5.1c0,0,0.1,0,0.1,0h50c2.8,0,5-2.2,5-5c0,0,0-0.1,0-0.1V41.5c0.1-2.8-2.1-5-4.9-5.1
	C75.2,36.4,75.1,36.4,75.1,36.4z M62,69.7c-1.1,1.2-2.9,1.2-4.1,0.1c0,0-0.1-0.1-0.1-0.1l-9.9-9.9c-0.6,0.3-1.3,0.4-2,0.5
	c-4.2,0.5-8-2.6-8.5-6.8c0-0.3,0-0.6,0-0.8c0-0.7,0.1-1.5,0.3-2.2c0.1-0.2,0.3-0.4,0.6-0.3c0.1,0,0.1,0,0.1,0.1l4.3,4.3
	c0.3,0.3,0.9,0.3,1.2,0l3-3c0.3-0.3,0.3-0.9,0-1.2l-4.3-4.3c-0.2-0.2-0.1-0.5,0.1-0.6c0,0,0.1-0.1,0.1-0.1c0.7-0.2,1.5-0.3,2.2-0.3
	c4.2,0,7.6,3.4,7.6,7.7c0,0.3,0,0.6,0,0.8c-0.1,0.7-0.3,1.3-0.5,2l9.9,9.9c1.3,1.1,1.5,2.9,0.4,4.2C62.2,69.7,62.2,69.8,62,69.7z"
      />
    </svg>
  );
};

export const IconCheckbox = (props: any) => {
  const { isIndeterminate, isChecked, ...rest } = props;

  return (
    <Icon viewBox="0 0 17 16" w="13px" h="13px" {...rest}>
      <path
        fill="white"
        d="M16.5838 0.281772C17.1564 0.728372 17.2694 1.56891 16.8362 2.15918L8.63694 13.3311C7.79957 14.472 6.15214 14.5186 5.25543 13.4266L0.507257 7.64431C0.0433595 7.07938 0.111521 6.23372 0.6595 5.75548C1.20748 5.27724 2.02777 5.34751 2.49166 5.91243L5.14152 9.13938C6.03822 10.2314 7.68565 10.1848 8.52302 9.04388L14.7628 0.541894C15.196 -0.0483669 16.0113 -0.164828 16.5838 0.281772Z"
      />
    </Icon>
  );
};
