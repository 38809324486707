import React from "react";

import {
  HomeIcon,
  BussinessIcon,
  ProductsIcon,
  MaintenanceIcon,
  ClientsIcon,
  SendNotificationIcon,
  AppealsIcon,
  InformationIcon,
} from "../icons";
import { paths } from "./paths";

const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const Buisiness = React.lazy(() => import("../pages/Buisiness/Buisiness"));
const Clients = React.lazy(() => import("../pages/Clients/Clients"));
const ClientAccount = React.lazy(
  () => import("../pages/ClientAccount/ClientAccount")
);
const Products = React.lazy(() => import("../pages/Products/Products"));
const Appeals = React.lazy(() => import("../pages/Appeals/Appeals"));
const Maintenance = React.lazy(
  () => import("../pages/Maintenance/Maintenance")
);
const SendNotification = React.lazy(
  () => import("../pages/SendNotification/SendNotification")
);
const Information = React.lazy(
  () => import("../pages/Information/Information")
);
const BuisinessView = React.lazy(
  () => import("../pages/BuisinessView/BuisinessView")
);
const AppealView = React.lazy(() => import("../pages/AppealView/AppealView"));

export interface IRoute {
  element: JSX.Element;
  pageTitle?: string;
  indexPath?: string;
  path: string;
  params: string;
  renderIcon?: (currentPath: string) => JSX.Element;
}

export const routes: IRoute[] = [
  {
    element: <Dashboard />,
    pageTitle: "Dashboard",
    params: "",
    path: paths.dashboard,
    renderIcon: (currentPath) => (
      <HomeIcon isActive={currentPath.includes(paths.dashboard)} />
    ),
  },
  {
    element: <Buisiness />,
    pageTitle: "Buisinesses",
    path: paths.buisiness,
    params: "",
    renderIcon: (currentPath) => (
      <BussinessIcon isActive={currentPath.includes(paths.buisiness)} />
    ),
  },
  {
    element: <Clients />,
    pageTitle: "Clients",
    path: paths.clients,
    params: "",
    renderIcon: (currentPath) => (
      <ClientsIcon isActive={currentPath.includes(paths.clients)} />
    ),
  },
  {
    element: <Products />,
    pageTitle: "Products",
    path: paths.products,
    params: "",
    renderIcon: (currentPath) => (
      <ProductsIcon isActive={currentPath.includes(paths.products)} />
    ),
  },
  {
    element: <Appeals />,
    pageTitle: "Appeals",
    params: "",
    path: paths.appeals,
    renderIcon: (currentPath) => (
      <AppealsIcon isActive={currentPath.includes(paths.appeals)} />
    ),
  },
  {
    element: <Information />,
    pageTitle: "Information",
    path: paths.information,
    params: "/:section",
    indexPath: "/faq",
    renderIcon: (currentPath) => (
      <InformationIcon isActive={currentPath.includes(paths.information)} />
    ),
  },
  {
    element: <SendNotification />,
    pageTitle: "Send Notification",
    path: paths.sendNotification,
    params: "",
    renderIcon: (currentPath) => (
      <SendNotificationIcon
        isActive={currentPath.includes(paths.sendNotification)}
      />
    ),
  },
  {
    element: <Maintenance />,
    pageTitle: "Device Maintenance",
    path: paths.maintenance,
    params: "",
    renderIcon: (currentPath) => (
      <MaintenanceIcon isActive={currentPath.includes(paths.maintenance)} />
    ),
  },
  {
    element: <ClientAccount />,
    params: "",
    path: paths.clientAccount + "/:clientId",
  },
  {
    element: <BuisinessView />,
    params: "",
    path: paths.buisiness + "/:buisinessId",
  },
  {
    element: <AppealView />,
    params: "",
    path: paths.appeals + "/:appealId",
  },
];
