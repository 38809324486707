import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {RootState} from "../../store";
import {SERVER_URL} from "../../../constants";
import {Business, Response} from "../../../interfaces";

export const businessApi = createApi({
  reducerPath: "businessApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_URL}/admin`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (build) => ({
    getBusinesses: build.query<any, number>({
      query: (page) => ({
        url: `/businesses?page=${page}&per_page=40`,
      }),
      transformResponse: (response: { data: any }) => response.data,
    }),
  }),
});

export const {useGetBusinessesQuery} = businessApi;