import { Box, Flex, Image, Text, VStack } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import userIcon from "../../images/SVG/user-placeholder.svg";
import appLogo from "../../images/SVG/logo.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import { logout } from "../../redux/slices/userSlice";
import DangerButton from "../DangerButton/DangerButton";
import NotificationMenu from "../NotificationMenu/NotificationMenu";

const Header = () => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useDispatch();

  return (
    <Flex
      w="full"
      align="center"
      h="70px"
      bg="brand.bars"
      pos="relative"
      justify="space-between"
      minH="70px"
      pl="20px"
      pr="20px"
      boxShadow="0px 11px 10px -15px #4c6e80"
    >
      <Flex align="center" w="full" maxW="1200px">
        <Flex align="center">
          <Image
            borderRadius="50%"
            src={user.image || userIcon}
            alt="user-icon"
            mr="20px"
            w="55px"
            h="55px"
          />
          <VStack
            h="full"
            spacing="10px"
            align="flex-start"
            w="auto"
            minW="150px"
          >
            <Text mb="auto" color="brand.action" fontSize="18px">
              {user.email}
            </Text>
            <DangerButton
              text="Logout"
              h="25px"
              w="80px"
              fontSize="12px"
              mt="auto"
              onClick={() => dispatch(logout())}
            />
          </VStack>
          <Box ml="30px">
            <NotificationMenu />
          </Box>
        </Flex>
      </Flex>
      <Image src={appLogo} alt="logo-icon" h="50px" />
    </Flex>
  );
};

export default Header;
