import { authApi } from "./services/auth";
import { persistReducer } from "redux-persist";
import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { rtkQueryErrorLogger } from "./middlewares/apiError";
import userReducer from "./slices/userSlice";
import {businessApi} from "./services/business";
import {infoApi} from "./services/info";
import {productsApi} from "./services/products";
import {countriesApi} from "./services/countries";
import {clientsApi} from "./services/clients";
import {productsCategoriesApi} from "./services/products/categories";

const rootReducer = combineReducers({
  user: userReducer,
  [authApi.reducerPath]: authApi.reducer,
  [businessApi.reducerPath]: businessApi.reducer,
  [infoApi.reducerPath]: infoApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [clientsApi.reducerPath]: clientsApi.reducer,
  [productsCategoriesApi.reducerPath]: productsCategoriesApi.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = () => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      // userApi.middleware,
      rtkQueryErrorLogger,
    ],
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
