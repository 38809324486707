import { Box, Center, Flex, Image, Progress } from "@chakra-ui/react";
import appLogo from "../../images/SVG/logo.svg";

const Fallback = () => {
  return (
    <Center w="full" h="calc(100% - 100px)">
      <Center maxW="200px" flexDir="column">
        <Progress
          w="150px"
          color="#4C6E80"
          borderRadius="10px"
          hasStripe={true}
          size="md"
          opacity="0.6"
          colorScheme="gray"
          isIndeterminate
        />
      </Center>
    </Center>
  );
};

export default Fallback;
