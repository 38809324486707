import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {RootState} from "../../store";
import {SERVER_URL} from "../../../constants";

export const countriesApi = createApi({
  reducerPath: "countriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_URL}/v1/businesses/countries`,
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).user.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (build) => ({
    getCountries: build.query<any, void>({
      query: () => ({
        url: "",
      }),
      transformResponse: (response: { data: any }) => response.data,
    }),
  }),
});

export const {useGetCountriesQuery} = countriesApi;